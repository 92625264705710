<template>
    <b-card-text>
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <b-col sm="12" class="my-1 mt-4">

            <!-- Select Actions and Items Per Page Options -->
            <b-row>
                <b-col sm="6" md="6">
                    <span class="details-view-title">Change Requests</span>
                    <div class="details-view-subtitle">List of change requests created on this account</div>
                </b-col>
                <b-col sm="6" md="4" offset-md="2" class="mt-2 text-md-right">
                    <b-input-group prepend="Show" append="/ Page">
                        <b-form-select :options="pageOptions" v-model="perPage" />
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
                :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" responsive>

                <template v-slot:cell(dateRequested)="row">
                    {{ getFormattedDate(row.item.dateRequested, 'MMMM D, YYYY') }}
                </template>

                <template v-slot:cell(changes)="row">
                    <span v-html="getFormattedChanges(row.item.changes)"></span>
                </template>

                <template v-slot:cell(status)="row">
                    <span v-if="row.item.status === 'For Approval'">
                        <b-badge variant="secondary">{{ row.item.status }}</b-badge>
                    </span>
                    <span v-if="row.item.status === 'Approved'">
                        <b-badge variant="success">{{ row.item.status }}</b-badge>
                    </span>
                    <span v-if="row.item.status === 'Rejected'">
                        <b-badge variant="danger">{{ row.item.status }}</b-badge>
                    </span>
                </template>

                <template v-slot:cell(requestReason)="row">
                    <i> {{ row.item.requestReason }}</i>
                </template>

                <template v-slot:cell(actions)="row">
                    <div class="fixed-column">
                        <span v-if="isAllowedToApproveOrReject(row.item)">
                            <b-button size="sm" v-b-tooltip.hover.top="'Approve'" variant="primary"
                                @click.stop="onClickAction(row.item, 'Approve')" class="mr-1">
                                <i class="fa fa-check"></i>
                            </b-button>
                            <b-button size="sm" v-b-tooltip.hover.top="'Reject'" variant="danger"
                                @click.stop="onClickAction(row.item, 'Reject')" class="mr-1">
                                <i class="fa fa-close"></i>
                            </b-button>
                        </span>
                        <span v-else>
                            <span class="action-readonly" v-html="getFormattedActionMessage(row.item)"></span>
                        </span>
                    </div>
                </template>
            </b-table>

            <b-row>
                <b-col md="8" sm="12" class="my-1">
                    <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                </b-col>
                <b-col md="4" sm="12" class="my-1">
                    <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                        class="my-0" />
                </b-col>
            </b-row>

        </b-col>
    </b-card-text>
</template>

<script>
// Utils
import { DifferenceUtil } from '@/utils/differenceUtil';

// DAO
import clientAccountDAO from '@/database/clientAccounts';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';


export default {
    name: 'client-account-change-requests-details-view',
    components: {
        Loading
    },
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: [],
            fields: [],
            defaultFields: [
                {
                    key: 'dateRequested',
                    sortable: true
                },
                'requestedBy',
                'changes',
                'requestReason',
                'status',
                {
                    key: 'actions',
                    thClass: 'text-center'
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            clientAccountId: '',

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isManager: this.$store.getters.isManager,

            // Check for loader
            isLoading: false,
        }
    },
    watch: {
        clientAccountId: function (newVal) {
            if (newVal && newVal.length > 0) {
                this.updateChangeRequests();
            }
        },
    },
    async mounted() {
        this.clientAccountId = this.row.item.id;
        await this.updateChangeRequests();
    },
    methods: {
        async updateChangeRequests() {
            try {
                // show loading indicator
                this.isLoading = true;

                this.items = [];

                let changeRequests = await clientAccountDAO.getAccountUpdateRequest(this.clientAccountId);

                _.forEach(changeRequests, changeRequest => {
                    changeRequest['changes'] = DifferenceUtil.diffClientAccounts(changeRequest.old, changeRequest.new);
                    this.items.push(changeRequest);
                });
                this.items = _.uniqBy(this.items, 'requestId');

                this.fields = [...this.defaultFields];
                if (!this.isManager && !this.isSuperAdmin) {
                    const index = this.fields.indexOf('actions');
                    if (index !== -1) {
                        this.fields.splice(index, 1);
                    }
                }

                this.totalRows = _.size(this.items);
                this.items = _.sortBy(this.items, ['dateRequested']);
                this.items.reverse();

            } catch (error) {
                this.$toaster.error('An error occurred while attempting to retrieve the list of change requests.');
            }

            // hide loading indicator
            this.isLoading = false;
        },
        isAllowedToApproveOrReject(request) {
            return (this.isManager || this.isSuperAdmin) && request.status === 'For Approval'
        },
        onClickAction(request, action) {
            EventBus.$emit('onConfirmAccountChangeRequest', {
                request: request,
                action: action
            });
        },

        getFormattedChanges(changes) {
            let formattedHtml = '';

            if (changes && changes.length > 0) {
                for (const change of changes) {
                    if (change.fieldUpdate !== 'Billable Rates' && change.fieldUpdate !== 'Asset Types') {
                        formattedHtml += `
					<span>
					<strong>${change.fieldUpdate}</strong><br/>
					From <strong style="color: #E93F19">${change.from}</strong> 
					To <strong style="color: #3F8120">${change.to}</strong>
					</span><br/><br/>
				`;
                    } else {
                        formattedHtml += `
					<span>
					<strong>${change.fieldUpdate}</strong><br/>
                    <span>${change.to}</span>
					</span><br/><br/>
				`;
                    }
                }
            }
            return formattedHtml;
        },
        getFormattedDate(date, format) {
            return moment(date).format(format)
        },
        getFormattedActionMessage(request) {
            if (request.status === 'For Approval') {
                return `Waiting for approval`;
            } else {
                // For 'Approved' or 'Rejected' status
                const timestamp = request.status === 'Approved' ? request.dateApproved : request.dateRejected;
                let editor = request.status === 'Approved' ? request.approvedBy : request.rejectedBy || '-';

                return `${request.status} last <br/><strong>${this.getFormattedDate(timestamp, 'MMMM D, YYYY hh:mm A')}</strong> <br/> by <strong>${editor}</strong>`;
            }
        },
    }
}
</script>

<style scoped>
.action-readonly {
    font-size: 14px;
    letter-spacing: 0px;
}

.fixed-column {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>

<template>
    <div class="text-nowrap">
        <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
            @click.stop="row.toggleDetails" class="mr-1">
            <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
            <i class="fa fa-eye" v-else></i>
        </b-button>
        <b-button v-if="isAllowedToEdit(row.item)" size="sm" v-b-modal.edit-client-account
            v-b-tooltip.hover.top="'Edit Details'" variant="warning" @click.stop="editClientAccount(row.item, 'edit')"
            class="mr-1" v-show="!isViewer">
            <i class="fa fa-pencil"></i>
        </b-button>
        <b-button size="sm" v-b-modal.print-client-account v-b-tooltip.hover.top="'Print Account'" variant="primary"
            @click.stop="editClientAccount(row.item, 'print')" class="mr-1">
            <em class="fa fa-print" style="color: #fff"></em>
        </b-button>
        <b-button v-if="isAllowedToActivate(row.item)" size="sm" v-b-tooltip.hover.top="'Activate Account'"
            variant="success" @click.stop="editClientAccount(row.item, 'activate')" class="mr-1" v-show="!isViewer">
            <em class="fa fa-play"></em>
        </b-button>
        <b-button v-if="isAllowedToTerminate(row.item)" size="sm" v-b-tooltip.hover.top="'Terminate Account'"
            variant="danger" @click.stop="editClientAccount(row.item, 'terminate')" class="mr-1" v-show="!isViewer">
            <em class="fa fa-stop"></em>
        </b-button>
    </div>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';

export default {
    name: 'client-account-row-actions',
    props: {
        row: {
            type: Object,
            required: true,
        },
        isSuperAdmin: {
            type: Boolean,
            required: true,
        },
        isManager: {
            type: Boolean,
            required: true,
        },
        isViewer: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        editClientAccount(clientAccount, action) {
            this.selClientAccount = clientAccount;
            this.$store.commit('SET_CURR_CLIENT_ACCOUNT', clientAccount);

            switch (action) {
                case 'edit':
                    EventBus.$emit('onUpdateClientAccount', clientAccount);
                    break;
                case 'print':
                    EventBus.$emit('onPrintClientAccount', clientAccount);
                    break;
                case 'activate':
                case 'terminate':
                    EventBus.$emit('onUpdateClientAccountStatus', { account: clientAccount, action: action });
                    break;
            }
        },
        isAllowedToEdit(account) {
            return account.status !== 'Expired' && account.status !== 'Terminated';
        },
        isAllowedToActivate(account) {
            return (this.isManager || this.isSuperAdmin) && account.status === 'Terminated';
        },
        isAllowedToTerminate(account) {
            return (this.isManager || this.isSuperAdmin) && account.status === 'Active';
        }
    }
}
</script>
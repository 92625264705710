<template>
    <div class="confirm-password my-4">
        <!-- Temp Field Holder for Auto-filled Values on Password to prevent unnecessary override on other screens with input type -->
        <b-form-input name="Username" class="sr-only" />

        <b-form-group label="Password" label-for="password">
            <b-input-group>
                <b-form-input name="Password" v-model="form.password" v-validate="{
                    required: true,
                    regex: passwordRegex,
                }" placeholder="Password" :type="showPassword ? 'text' : 'password'" autocomplete="off" />
                <b-input-group-append>
                    <b-button @click="showPassword = !showPassword">
                        <span>
                            <b-icon :icon="showPassword ? 'eye-slash' : 'eye'" />
                        </span>
                    </b-button>
                </b-input-group-append>
                <span v-show="errors.has('Password')" class="help-block">{{
                    passwordErrorMsg
                }}</span>
            </b-input-group>
        </b-form-group>

        <b-form-group label="Confirm Password" label-for="confirmPassword" description>
            <b-input-group>
                <b-form-input name="Confirm Password" v-model="form.confirmPassword" v-validate="{
                    required: true,
                    regex: passwordRegex,
                }" placeholder="Confirm Password" :type="showConfirmPassword ? 'text' : 'password'"
                    autocomplete="off" />
                <b-input-group-append>
                    <b-button @click="showConfirmPassword = !showConfirmPassword">
                        <span>
                            <b-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />
                        </span>
                    </b-button>
                </b-input-group-append>
                <span v-show="errors.has('Confirm Password')" class="help-block">{{
                    passwordErrorMsg
                }}</span>
            </b-input-group>
        </b-form-group>
    </div>
</template>

<script>
// Others
import config from '@/config/env-constants';

export default {
    name: 'confirm-password',
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showPassword: false,
            showConfirmPassword: false,
        }
    },
    computed: {
        passwordRegex() {
            return config.passwordRegex;
        },
        passwordErrorMsg() {
            return config.passwordErrorMsg;
        },
    },
    mounted() {
        this.form.password = '';
        this.form.confirmPassword = '';
    }
}
</script>
<template>
    <b-row class="mt-3">
        <b-col sm="3" align="left">
        </b-col>
        <b-col class="footer-col fixed-footer" sm="9" align="right">
            <span class="copy-owner" v-if="copyOwner.length > 0">{{ copyOwner + ' Copy' }}</span>
            <span class="pagination-status" v-show="totalPage > 1">
                <span v-if="copyOwner.length > 0">&nbsp;|</span>
                Page <strong>{{ currPage + 1 }}</strong> of <strong>{{ totalPage }}</strong>
            </span>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'client-account-summary-footer',
    props: {
        currPage: {
            type: Number,
            required: true,
        },
        totalPage: {
            type: Number,
            required: true,
        },
        copyOwner: {
            type: String,
            required: true
        }
    },
}
</script>

<style scoped>
.footer-col {
    padding-top: 0.7em;
    font-size: 12px;
}

.copy-owner {
    text-transform: uppercase;
}

.fixed-footer {
    position: absolute;
    /* Allows positioning within the parent container */
    bottom: 30px;
    /* Distance from the bottom edge */
    right: 20px;
    padding: 10px;
}
</style>
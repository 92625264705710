<template>
  <div v-if="rentals.length > 0">
    <div class="rental-header text-uppercase mb-2">Rental
      <span class="desc text-muted text-capitalize">(For Rent)</span>
    </div>
    <b-row class="section-header border border-light border-1 border-bottom-0 py-1">
      <b-col sm="3">Asset Type</b-col>
      <b-col sm="3">Contracted Quantity</b-col>
      <b-col sm="3">Rent Frequency</b-col>
      <b-col sm="3">Rent Rate</b-col>
    </b-row>
    <b-row class="form-field border border-light border-1 py-1">
      <b-col sm="3">{{ rentals[0].asset }}</b-col>
      <b-col sm="3">{{ rentals[0].quantity.toLocaleString() }}</b-col>
      <b-col sm="3">{{ rentals[0].rentFrequency }}</b-col>
      <b-col sm="3">{{ formatMoneyValue('PHP', rentals[0].rentRate) }}</b-col>
    </b-row>
  </div>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

export default {
  name: 'client-account-summary-rental',
  props: {
    rentals: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatMoneyValue(currency, number) {
      return BillingReportUtil.formatMoneyValue(currency, number);
    }
  }
}
</script>

<style scoped>
.rental-header {
  color: #122C91;
  font-size: 1em;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-left: 3px;
  margin-right: 3px;
}

.desc {
  font-style: italic;
  font-weight: 100;
}

.section-header {
  color: #4A4A4A;
  background-color: #EEEEEE;
  font-size: small;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-align: left;
  margin-left: 3px;
  margin-right: 3px;
}

.form-field {
  font-size: small;
  color: #4A4A4A;
  line-height: normal;
  margin-left: 3px;
  margin-right: 3px;
}
</style>
<template>
    <div v-if="repairs.length > 0">
        <b-row>
            <b-col v-for="(repair, key) in assetsBy4" :key="key">
                <div v-for="(item, key) in repair" :key="key">
                    <div class="mb-2 mt-3">
                        <div class="repair-header text-uppercase mb-2">{{ item.condition }} <span
                                class="desc text-muted text-capitalize">({{ item.description }})</span></div>
                        <b-row class="section-header border border-light border-1 border-bottom-0 py-1">
                            <b-col sm="6">Asset Type</b-col>
                            <b-col sm="6">VAT Ex Rate</b-col>
                        </b-row>
                        <b-row class="form-field border border-light border-1 py-1">
                            <b-col sm="6">
                                {{ getAssetType(item.asset) }}
                                <span v-if="item.asset.length === 0">&nbsp;</span>
                            </b-col>
                            <b-col sm="6">{{ formatMoneyValue('PHP', item.vatExRate) }}</b-col>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

// Others
import _ from 'lodash';

export default {
    name: 'client-account-summary-repair',
    props: {
        rentals: {
            type: Array,
            required: true
        },
        repairs: {
            type: Array,
            required: true
        }
    },
    computed: {
        repair() {
            return this.getBillableRates(this.rentals[0], this.repairs);
        },
        assetsBy4() {
            return _.chunk(this.repair, 4);
        }
    },
    methods: {
        getMaxCount(currCount) {
            let maxCount = currCount;
            if (currCount <= 4) {
                maxCount = 4;
            } else if (currCount <= 8) {
                maxCount = 8;
            } else if (currCount <= 12) {
                maxCount = 12;
            }
            return maxCount;
        },
        getBillableRates(rentals, repairs) {
            let billableRates = [];

            const filteredRepairs = repairs.filter(repair => {
                return repair.asset === rentals.asset;
            });

            billableRates = billableRates.concat(filteredRepairs);
            return billableRates;
        },
        getAssetType(string) {
            const maxCount = this.getMaxCount(_.size(this.repair));

            if (maxCount > 10) {
                return string.substring(0, 20) + '...';
            }
            return string;
        },
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        }
    }
}
</script>

<style scoped>
.repair-header {
    color: #122C91;
    font-size: 1em;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 3px;
    margin-right: 3px;
}

.desc {
    font-style: italic;
    font-weight: 100;
}

.section-header {
    color: #4A4A4A;
    background-color: #EEEEEE;
    font-size: small;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-align: left;
    margin-left: 3px;
    margin-right: 3px;
}

.form-field {
    font-size: small;
    color: #4A4A4A;
    line-height: normal;
    margin-left: 3px;
    margin-right: 3px;
}
</style>
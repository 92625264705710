<template>
	<b-card>
		<b-tabs>
			<b-tab title="Primary Details" active>
				<b-card-text>
					<b-form-group label="Asset Owner" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.assetOwner }}
					</b-form-group>

					<b-form-group label="Account No" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">
							{{ row.item.accountNo }}
						</span>
					</b-form-group>

					<b-form-group label="Client" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.client }}
					</b-form-group>

					<b-form-group label="Principal" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.principal ? row.item.principal : '-' }}
					</b-form-group>

					<b-form-group label="Account Manager" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.accountManager }}
					</b-form-group>

					<b-form-group label="Client Manager" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.clientManager }}
					</b-form-group>

					<b-form-group label="Remarks" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="truncate-text">
							<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
								type="html" :text="row.item.remarks" />
						</span>
					</b-form-group>

					<b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<ClientAccountRowStatus :row="row"/>
					</b-form-group>

					<span v-if="row.item.status === 'Active' || row.item.status === 'Inactive'">
						<b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ getFormattedDateWithTime(row.item.dateUpdated) }}
						</b-form-group>
						<b-form-group label="Updated By" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.updatedBy }}
						</b-form-group>
					</span>
					<span v-else-if="row.item.status === 'Terminated'">
						<b-form-group label="Date Terminated" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ getFormattedDateWithTime(row.item.dateTerminated) }}
						</b-form-group>
						<b-form-group label="Terminated By" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.terminatedBy }}
						</b-form-group>
					</span>
					<span v-else-if="row.item.status === 'Expired'">
						<b-form-group label="Date Expired" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ getFormattedDateWithTime(row.item.endingDate) }}
						</b-form-group>
						<b-form-group label="Expired By" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<i>System-Generated</i>
						</b-form-group>
					</span>
				</b-card-text>
			</b-tab>

			<b-tab title="Billing Details">
				<b-card-text>
					<b-form-group label="Contract Period" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.contractPeriod }}
					</b-form-group>

					<b-form-group label="Contract Start Date" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDate(row.item.startDate) }}
					</b-form-group>

					<b-form-group label="Billing Frequency" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getBillingFrequency(row.item.billingFrequency, row.item.billingSchedule) }}
					</b-form-group>

					<b-form-group label="Payment Terms" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">{{ row.item.paymentTerms.toLocaleString() }}</span> Days
					</b-form-group>

					<b-form-group label="Total Billings" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">{{ row.item.totalBillings.toLocaleString() }}</span>
					</b-form-group>
				</b-card-text>
			</b-tab>

			<b-tab title="Asset Types">
				<ClientAccountAssetTypesDetailsView :row="row" />
			</b-tab>

			<b-tab title="Change Requests">
				<ClientAccountChangeRequestsDetailsView :row="row" />
			</b-tab>

			<b-tab title="Change Logs">
				<ClientAccountChangeLogsDetailsView :row="row" />
			</b-tab>
		</b-tabs>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary"
					v-b-tooltip.hover.top="'Hide Details'">
					<em class="icon-arrow-up"></em>
				</b-button>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
// Components
import ClientAccountAssetTypesDetailsView from './ClientAccountAssetTypesDetailsView';
import ClientAccountChangeRequestsDetailsView from './ClientAccountChangeRequestsDetailsView';
import ClientAccountChangeLogsDetailsView from './ClientAccountChangeLogsDetailsView';
import ClientAccountRowStatus from './ClientAccountRowStatus';

// Util
import { ClientAccountUtil } from '@/utils/clientAccountUtil';
import { DateUtil } from '@/utils/dateutil';

// Others
import truncate from 'vue-truncate-collapsed';

export default {
	name: 'client-account-details-view',
	components: {
		ClientAccountAssetTypesDetailsView,
		ClientAccountChangeRequestsDetailsView,
		ClientAccountChangeLogsDetailsView,
		ClientAccountRowStatus,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		}
	},
	methods: {
		getFormattedDate(date) {
			return DateUtil.getFormattedDate(date);
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getBillingFrequency(frequency, schedule) {
			return ClientAccountUtil.getBillingFrequency(frequency, schedule);
		}
	},
};
</script>
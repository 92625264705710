<template>
	<div>
		<span v-if="row.item.status === 'Inactive'">
			<b-badge variant="secondary">{{ row.item.status }}</b-badge>
		</span>
		<span v-if="row.item.status === 'Active'">
			<b-badge variant="success">{{ row.item.status }}</b-badge>
		</span>
		<span v-if="row.item.status === 'Terminated'">
			<b-badge variant="danger">{{ row.item.status }}</b-badge>
		</span>
		<span v-if="row.item.status === 'Expired'">
			<b-badge variant="primary">{{ row.item.status }}</b-badge>
		</span>
	</div>
</template>

<script>
export default {
	name: 'client-account-row-status',
	props: {
		row: {
			type: Object,
			required: true
		}
	}
};
</script>


<template>
    <b-card-text>
        <b-col sm="12" class="my-1 mt-4">

            <!-- Select Actions and Items Per Page Options -->
            <b-row>
                <b-col sm="6" md="6">
                    <span class="details-view-title">Asset Types</span>
                    <div class="details-view-subtitle">List of asset types rented on this account</div>
                </b-col>
                <b-col sm="6" md="4" offset-md="2" class="mt-2 text-md-right">
                    <b-input-group prepend="Show" append="/ Page">
                        <b-form-select :options="pageOptions" v-model="perPage" />
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
                :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-direction="sortDirection" responsive>

                <template v-slot:cell(rentRate)="row">
                    <span class="numFont">
                        {{ getRentRateDisplay(row.item.rentRate) }}
                    </span>
                </template>

                <template v-slot:cell(quantity)="row">
                    <span class="numFont">
                        {{ getQuantityDisplay(row.item.quantity) }}
                    </span>
                </template>

            </b-table>

            <b-row>
                <b-col md="8" sm="12" class="my-1">
                    <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                </b-col>
                <b-col md="4" sm="12" class="my-1">
                    <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                        class="my-0" />
                </b-col>
            </b-row>
        </b-col>
    </b-card-text>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

// Others
import _ from 'lodash';

export default {
    name: 'client-account-asset-type-details-view',
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'assetType',
                    sortable: true,
                },
                'rentFrequency',
                'rentRate',
                {
                    key: 'quantity',
                    label: 'Contracted Quantity',
                    sortable: true,
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            clientAccountId: '',
        }
    },
    watch: {
        clientAccountId: function (newVal) {
            if (newVal && newVal.length > 0) {
                this.updateAssetTypes();
            }
        },
    },
    mounted() {
        this.clientAccountId = this.row.item.id;
        this.updateAssetTypes();
    },
    methods: {
        updateAssetTypes() {
            this.items = [];

            let counter = 0;
            const assetsObj = _.sortBy(this.row.item.assetTypes, ['assetType']);
            _.forEach(assetsObj, value => {
                this.items.push({
                    index: ++counter,
                    assetType: value.assetType,
                    rentFrequency: value.rentFrequency,
                    rentRate: value.rentRate,
                    quantity: value.quantity
                });
            });

            this.totalRows = _.size(this.items);
        },
        getRentRateDisplay(rentRate) {
            return BillingReportUtil.formatMoneyValue('PHP', rentRate);
        },
        getQuantityDisplay(quantity) {
            return BillingReportUtil.formatNumber(quantity);
        }
    }
}
</script>
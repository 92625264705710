var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"confirm-password my-4"},[_c('b-form-input',{staticClass:"sr-only",attrs:{"name":"Username"}}),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: true,
                regex: _vm.passwordRegex,
            }),expression:"{\n                required: true,\n                regex: passwordRegex,\n            }"}],attrs:{"name":"Password","placeholder":"Password","type":_vm.showPassword ? 'text' : 'password',"autocomplete":"off"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('span',[_c('b-icon',{attrs:{"icon":_vm.showPassword ? 'eye-slash' : 'eye'}})],1)])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Password')),expression:"errors.has('Password')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.passwordErrorMsg))])],1)],1),_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirmPassword","description":""}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: true,
                regex: _vm.passwordRegex,
            }),expression:"{\n                required: true,\n                regex: passwordRegex,\n            }"}],attrs:{"name":"Confirm Password","placeholder":"Confirm Password","type":_vm.showConfirmPassword ? 'text' : 'password',"autocomplete":"off"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}}},[_c('span',[_c('b-icon',{attrs:{"icon":_vm.showConfirmPassword ? 'eye-slash' : 'eye'}})],1)])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Confirm Password')),expression:"errors.has('Confirm Password')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.passwordErrorMsg))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <b-modal id="confirm-account-update" ref="confirm-account-update" :size="modalSize" :ok-title="modalOkTitle"
        cancel-title="Cancel" @cancel="onReset" @ok="handleOk" :cancel-disabled="disableConfirmButtons"
        :ok-disabled="disableConfirmButtons" ok-variant="primary" :no-close-on-backdrop="true">
        <template #modal-header>
            <div class="modal-title">
                <i class="fa fa-check"></i>
                <span>&nbsp;&nbsp;{{ modalTitle }}</span>
            </div>
        </template>

        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-container fluid>
            <div v-if="!isApprove">
                <b-row class="mb-3">
                    <b-col sm="12">
                        <strong>{{ clientName }} - {{ accountNo }}</strong>
                        account has already started last <strong>{{ getStartDate(changeRequest.new) }}</strong>.
                        Any reason for an update must be justified. Please state your reason for updating its details.
                    </b-col>
                </b-row>

                <b-row class="mb-2" v-if="(!isManager && !isSuperAdmin) && items.length > 0">
                    <b-col sm="12">
                        <b-table show-empty striped hover :items="items" :fields="fields">
                            <template v-slot:cell(from)="row">
                                <span v-html="row.item.from"></span>
                            </template>
                            <template v-slot:cell(to)="row">
                                <span v-html="row.item.to"></span>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12">
                        <b-form-group label="Reason" label-for="Reason" description>
                            <b-form-textarea name="Reason" type="text" v-model="changeRequest.requestReason"
                                maxlength="200" v-validate="getValidationParam(true, remarksRegex)" :rows="3"
                                placeholder="Place your reason for update here." />
                            <span v-show="errors.has('Reason')" class="help-block">
                                {{ errors.first('Reason') }}
                            </span>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="!isManager && !isSuperAdmin">
                    <b-col sm="12">
                        <span class="info" v-html="getConfirmApproveTxt()"></span>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <!-- Confirm Changes Section -->
                <strong>To save your changes, kindly input your password.</strong>
                <ConfirmPassword :form="form" />
            </div>

        </b-container>
    </b-modal>
</template>

<script>
// Components
import ConfirmPassword from '@/views/commons/ConfirmPassword';

// Utils
import { ClientAccountUtil } from '@/utils/clientAccountUtil';
import { DateUtil } from '@/utils/dateutil';
import { DifferenceUtil } from '@/utils/differenceUtil';

// API
import clientAccountApi from '@/api/clientAccountApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { firebase } from '@/config/firebase';
import moment from 'moment';

export default {
    name: 'confirm-account-update',
    components: {
        ConfirmPassword,
        Loading,
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'fieldUpdate',
                    label: 'Field Updated'
                },
                'from',
                'to',
            ],

            isApprove: false,
            changeRequest: { ...config.changeRequestDefault },
            form: {
                password: '',
                confirmPassword: ''
            },

            modalTitle: 'Confirm Account Update',
            modalOkTitle: 'Save',
            modalSize: 'lg',

            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isManager: this.$store.getters.isManager,
            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
        passwordRegex() {
            return config.passwordRegex;
        },
        remarksRegex() {
            return config.remarksRegex;
        },
        clientName() {
            let request = this.changeRequest ? this.changeRequest : {};
            return request.old && request.old.client ? request.old.client : '-';
        },
        accountNo() {
            let request = this.changeRequest ? this.changeRequest : {};
            return request.old && request.old.accountNo ? request.old.accountNo : '-';
        }
    },
    mounted() {
        EventBus.$on('onConfirmAccountUpdate', (param) => {
            if (this.$refs['confirm-account-update']) {
                this.$refs['confirm-account-update'].show();
            }
            this.items = DifferenceUtil.diffClientAccounts(param.old, param.new);
            this.changeRequest.new = param.new;
            this.changeRequest.old = param.old;
            this.changeRequest.requestedBy = this.loggedUser.id;
            this.changeRequest.requestId = 'CR' + DateUtil.getCurrentTimestamp();
            this.changeRequest.dateRequested = DateUtil.getCurrentTimestamp();
        });
    },
    methods: {
        getValidationParam(isRequired, regex) {
            return {
                required: isRequired,
                regex: regex,
            };
        },
        getConfirmApproveTxt() {
            return '<i class="fa fa-info-circle" aria-hidden="true"></i> By clicking <strong>Save</strong>, your changes will be under review. Kindly wait for the manager\'s approval for the change(s) to take effect.'
        },
        async handleOk(evt) {
            evt.preventDefault();

            // show loading indicator
            this.isLoading = true;

            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                // hide loading indicator
                this.isLoading = false;
                return;
            }

            if ((this.isManager || this.isSuperAdmin) && this.requiresApproval()) {
                if (!this.isApprove) {
                    this.saveAccountUpdate();
                } else {
                    await this.confirmAccountUpdate();
                }
            } else {
                await this.handleSubmit();
            }

        },

        saveAccountUpdate() {
            this.modalTitle = 'Confirm Changes';
            this.modalOkTitle = 'Confirm';
            this.modalSize = "md";
            this.isApprove = true;
            this.isLoading = false;
        },

        async confirmAccountUpdate() {
            // Updated by
            this.changeRequest.new.updatedBy = this.loggedUser.id;
            this.changeRequest.new.dateUpdated = DateUtil.getCurrentTimestamp();

            if (this.form.password !== this.form.confirmPassword) {
                this.$toaster.warning('Password does not match. Please try again.');
                this.isLoading = false;
            } else {
                await this.autheticateRequest();
            }
        },

        async autheticateRequest() {
            try {
                const credentials = firebase.auth.EmailAuthProvider.credential(this.loggedUser.id, this.form.password);
                const user = firebase.auth().currentUser;
                user.reauthenticateWithCredential(credentials)
                    .then(async () => {
                        await this.handleSubmit();
                    })
                    .catch(() => {
                        this.$toaster.warning('Account password does not match. Please try again.');
                        this.isLoading = false;
                    });
            } catch (_error) {
                this.$toaster.error('Error authenticating account. Please try again.');
                this.isLoading = false;
            }
        },

        async handleSubmit() {
            try {
                this.isLoading = true;

                let accountObj = this.changeRequest.new;
                accountObj.updateReason = this.changeRequest.requestReason;

                // Fields cleanup
                accountObj = ClientAccountUtil.cleanupFields(accountObj);

                let isSuccess = false;
                let errorMessage = '';

                if ((this.isManager || this.isSuperAdmin) || !this.requiresApproval()) {
                    isSuccess = await this.updateClientAccount(accountObj);
                    errorMessage = `Error updating client account "${accountObj.accountNo}". Please try again.`;
                } else {
                    isSuccess = await this.saveAccountUpdateRequest(this.changeRequest);
                    errorMessage = 'Error saving update request. Please try again.';
                }

                if (isSuccess) {
                    this.handleSuccess(accountObj);
                } else {
                    this.$toaster.error(errorMessage);
                }
            } catch (error) {
                const accountNo = this.changeRequest.new.accountNo;
                const errorMessage = `There was an error submitting update request for client account no. ${accountNo}. Please try again later.`;
                this.$toaster.error(errorMessage);
            } finally {
                this.isLoading = false;
            }
        },

        async updateClientAccount(clientAccountObj) {
            const { data } = await clientAccountApi.saveClientAccount(
                clientAccountObj,
                this.loggedUser.id,
                DateUtil.getCurrentTimestamp()
            );
            if (data.isSuccess) {
                EventBus.$emit('onCloseSaveClientAccount', data.clientAccount);
            }
            return data.isSuccess;
        },

        async saveAccountUpdateRequest(changeRequest) {
            const { data } = await clientAccountApi.saveAccountUpdateRequest(
                changeRequest,
                this.loggedUser.id,
                DateUtil.getCurrentTimestamp()
            );
            return data.isSuccess;
        },

        handleSuccess(clientAccount) {
            this.$toaster.success(`Client account "${clientAccount.accountNo}" was updated successfully.`);
            this.$refs['confirm-account-update'].hide();
            this.onReset();
        },

        onReset() {
            this.items = [];
            this.isApprove = false;

            this.changeRequest.requestId = 'CR' + DateUtil.getCurrentTimestamp();
            this.changeRequest.requestedBy = '';
            this.changeRequest.dateRequested = DateUtil.getCurrentTimestamp();
            this.changeRequest.requestReason = '';
            this.changeRequest.approvedBy = '';
            this.changeRequest.dateApproved = 0;
            this.changeRequest.rejectedBy = '';
            this.changeRequest.dateRejected = 0;
            this.changeRequest.status = 'For Approval';
            this.changeRequest.old = null;
            this.changeRequest.new = null;

            this.form.password = '';
            this.form.confirmPassword = '';

            this.modalTitle = 'Confirm Account Update ';
            this.modalOkTitle = 'Save';
            this.modalSize = 'lg';
        },

        requiresApproval() {
            let isRequired = false;
            for (const item of this.items) {
                if (item.critical) {
                    isRequired = true;
                }
            }
            return isRequired;
        },

        getClient(account) {
            return account && account.client ? account.client : 'N/A';
        },
        getStartDate(account) {
            return account && account.startDate ? this.getFormattedDate(account.startDate) : 'N/A';
        },
        getFormattedDate(date) {
            return moment(date).format('MMMM D, YYYY');
        },
    },
    beforeDestroy() {
        EventBus.$off('onConfirmAccountUpdate');
    },
};
</script>
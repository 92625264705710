<template>
    <b-modal v-model="show" id="activate-client-account" ref="activate-client-account" :ok-title="modalOkTitle"
        cancel-title="Cancel" @cancel="onReset" @ok="handleOk" :cancel-disabled="disableConfirmButtons"
        :ok-disabled="disableConfirmButtons" ok-variant="primary" :no-close-on-backdrop="true">
        <template #modal-header>
            <div class="modal-title">
                <em v-if="modalTitle !== 'Confirm Changes' && action === 'activate'" class="fa fa-play"></em>
                <em v-else-if="modalTitle !== 'Confirm Changes' && action === 'terminate'" class="fa fa-stop"></em>
                <em v-else-if="modalTitle === 'Confirm Changes'" class="fa fa-check"></em>
                <span>&nbsp;&nbsp;{{ modalTitle }}</span>
            </div>
        </template>

        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-container fluid>
            <b-row class="mb-3">
                <b-col sm="12">
                    <div class="modal-header-txt">{{ clientName }} - {{ accountNo }}</div>
                    <div class="mt-2" v-html="getInstructionsTxt(action)"></div>
                </b-col>
            </b-row>

            <b-row v-if="!isApprove && action === 'activate'">
                <b-col sm="12">
                    <b-form-group label="Activation Date" label-for="Activation Date" description>
                        <b-form-datepicker name="Activation Date" v-model="dateActivated" locale="en" reset-button
                            label-reset-button="Clear" :date-format-options="dateFormatOptions"
                            :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                        <span v-show="errors.has('Activation Date')" class="help-block">
                            {{ errors.first('Activation Date') }}
                        </span>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group label="Reason" label-for="Reason" description>
                        <b-form-textarea name="Reason for Activation" type="text" v-model="account.activateReason"
                            maxlength="200" v-validate="{
                            required: true,
                            regex: remarksRegex,
                        }" :rows="3" placeholder="Place your reason for activation here." />
                         <span v-show="errors.has('Reason for Activation')" class="help-block">
                            {{ errors.first('Reason for Activation')  }}
                        </span>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row v-else-if="!isApprove && action === 'terminate'">
                <b-col sm="12">
                    <b-form-group label="Termination Date" label-for="Termination Date" description>
                        <b-form-datepicker name="Termination Date" v-model="dateTerminated" locale="en" reset-button
                            label-reset-button="Clear" :date-format-options="dateFormatOptions"
                            :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                        <span v-show="errors.has('Termination Date')" class="help-block">
                            {{ errors.first('Termination Date') }}
                        </span>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group label="Reason" label-for="Reason" description>
                        <b-form-textarea name="Reason for Termination" type="text" v-model="account.terminationReason"
                            maxlength="200" v-validate="{
                            required: true,
                            regex: remarksRegex,
                        }" :rows="3" placeholder="Place your reason for termination here." />
                        <span v-show="errors.has('Reason for Termination')" class="help-block">
                            {{ errors.first('Reason for Termination') }}
                        </span>
                    </b-form-group>
                </b-col>
            </b-row>

            <div v-else>
                <!-- Confirm Changes Section -->
                <strong>To save your changes, kindly input your password.</strong>
                <ConfirmPassword :form="form" />
            </div>

        </b-container>

        <template #modal-footer>
            <div class="w-100">
                <span class="float-right">
                    <b-button variant="secondary" @click="show = false" class="footer-button">
                        Cancel
                    </b-button>
                    <b-button :variant="action === 'activate' ? 'success' : 'danger'" @click="handleOk"
                        class="footer-button">
                        {{ modalOkTitle }}
                    </b-button>
                </span>
            </div>
        </template>
    </b-modal>
</template>

<script>
// Components
import ConfirmPassword from '@/views/commons/ConfirmPassword';

// Utils
import { ClientAccountUtil } from '@/utils/clientAccountUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import clientAccountApi from '@/api/clientAccountApi';

// Others
import config from '@/config/env-constants';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import { firebase } from '@/config/firebase';

export default {
    name: 'confirm-account-status-update',
    components: {
        ConfirmPassword,
        DateRangePicker,
        Loading,
    },
    data() {
        return {
            show: false,
            action: '',
            account: {},

            form: {
                password: '',
                confirmPassword: ''
            },

            dateActivated: new Date(),
            dateTerminated: new Date(),
            modalTitle: '',
            modalOkTitle: 'Activate',
            isApprove: false,
            totalRows: 0,

            dateFormatOptions: { ...config.dateFormatOptions },

            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
        passwordRegex() {
            return config.passwordRegex;
        },
        remarksRegex() {
            return config.remarksRegex;
        },
        clientName() {
            let account = this.account ? this.account : {};
            return account.client ? account.client : '-';
        },
        accountNo() {
            let account = this.account ? this.account : {};
            return account.accountNo ? account.accountNo : '-';
        }
    },
    mounted() {
        EventBus.$on('onUpdateClientAccountStatus', (param) => {
            this.$bvModal.show('activate-client-account');

            this.action = param.action;
            this.account = JSON.parse(JSON.stringify(param.account));
            if (this.action === 'activate') {
                this.modalTitle = 'Activate Account';
                this.modalOkTitle = 'Activate';
                this.form.dateActivated = this.account.status === 'Inactive' ? this.account.startDate : DateUtil.getCurrentTimestamp();
                this.account.activateReason = '';
            } else {
                this.modalTitle = 'Terminate Account';
                this.modalOkTitle = 'Terminate';
                this.account.dateTerminated = DateUtil.getCurrentTimestamp();
                this.account.terminationReason = '';
            }
        });
    },
    methods: {
        getInstructionsTxt(action) {
            return action === 'activate' ? 'You are about to <strong>activate</strong> this account. Please fill out the fields below with the necessary details to proceed.'
                : 'You are about to <strong>terminate</strong> this account. Please fill out the fields below with the necessary details to proceed.';
        },
        dateFromDisabled(_ymd, date) {
            return date > new Date();
        },

        async handleOk(evt) {
            evt.preventDefault();
            this.isLoading = true;

            const isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                this.isLoading = false;
                return;
            }

            if (!this.isApprove) {
                this.saveAccountUpdate();
            } else {
                await this.confirmAccountUpdate();
            }
        },

        saveAccountUpdate() {
            this.modalTitle = 'Confirm Changes';
            this.modalOkTitle = 'Confirm';
            this.isApprove = true;
            this.isLoading = false;
        },

        async confirmAccountUpdate() {
            if (this.action === 'activate') {
                this.account.dateActivated = new Date(this.dateActivated).getTime();
                this.account.activatedBy = this.loggedUser.id;
                this.account.dateUpdated = new Date(this.dateActivated).getTime();
                this.account.updatedBy = this.loggedUser.id;
                this.account.status = 'Active';
            } else {
                this.account.dateTerminated = new Date(this.dateTerminated).getTime();
                this.account.terminatedBy = this.loggedUser.id;
                this.account.dateUpdated = new Date(this.dateTerminated).getTime();
                this.account.updatedBy = this.loggedUser.id;
                this.account.status = 'Terminated';
            }

            if (this.form.password !== this.form.confirmPassword) {
                this.$toaster.warning('Password does not match. Please try again.');
                this.isLoading = false;
            } else {
                await this.autheticateRequest();
            }
        },
        async autheticateRequest() {
            try {
                const credentials = firebase.auth.EmailAuthProvider.credential(this.loggedUser.id, this.form.password);
                const user = firebase.auth().currentUser;
                user.reauthenticateWithCredential(credentials)
                    .then(async () => {
                        await this.handleSubmit();
                    })
                    .catch(() => {
                        this.$toaster.warning('Account password does not match. Please try again.');
                        this.isLoading = false;
                    });
            } catch (_error) {
                this.$toaster.error('Error authenticating account. Please try again.');
                this.isLoading = false;
            }
        },
        async handleSubmit() {
            try {
                this.isLoading = true;

                // Fields cleanup
                this.account = ClientAccountUtil.cleanupFields(this.account);

                let { data } = await clientAccountApi.saveClientAccount(
                    this.account,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    this.handleSuccess(data.clientAccount);
                } else {
                    this.handleFailure(`Error updating client account "${this.account.accountNo}". Please try again.`);
                }
            } catch (error) {
                this.handleFailure(`There was an error submitting update request for client account no. ${this.account.accountNo}. Please try again later.`);
            } finally {
                this.isLoading = false;
            }
        },

        handleSuccess(clientAccount) {
            EventBus.$emit('onCloseSaveClientAccount', clientAccount);

            if (this.action === 'activate') {
                this.$toaster.success(`Client account "${clientAccount.accountNo}" was ACTIVATED successfully.`);
            } else {
                this.$toaster.success(`Client account "${clientAccount.accountNo}" was TERMINATED successfully.`);
            }

            this.$refs['activate-client-account'].hide();
            this.onReset();
        },

        handleFailure(errorMessage) {
            this.$toaster.error(errorMessage);
        },

        formatDate(timestamp) {
            return moment(timestamp).format('MMMM D YYYY');
        },

        onReset() {
            this.isLoading = false;
            this.isApprove = false;

            this.form.password = '';
            this.form.confirmPassword = '';

            this.modalTitle = 'Activate Account';
            this.modalOkTitle = 'Activate';

            this.action = '';
            this.account = {};
            this.show = true;
        },
    },
    beforeDestroy() {
        EventBus.$off('onUpdateClientAccountStatus');
    },
};
</script>

<style scoped>
.footer-button {
    margin-left: 5px;
}
</style>
import { db } from '@/config/firebase';


function generateQuery(filter) {
    let query = db.collection("clientAccounts");

    if (filter.fromTimestamp && filter.fromTimestamp > 0) {
        query = query.where("endingDate", ">=", filter.fromTimestamp);
    }
    if (filter.toTimestamp && filter.toTimestamp > 0) {
        query = query.where("endingDate", ">=", filter.toTimestamp);
    }
    if (filter.assetOwner && filter.assetOwner.id && filter.assetOwner.id.length > 0) {
        query = query.where("assetOwnerId", "==", filter.assetOwner.id);
    }
    if (filter.client && filter.client.id && filter.client.id.length > 0) {
        query = query.where("clientId", "==", filter.client.id);
    }
    if (filter.status && filter.status.length > 0) {
        query = query.where("status", "==", filter.status);
    }
    if (filter.clientAccount && filter.clientAccount.accountNo && filter.clientAccount.accountNo.length > 0) {
        query = query.where("accountNo", "==", filter.clientAccount.accountNo);
    }

    return query;
}

function getAccountListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

async function saveAccountUpdateRequest(changeRequestObj, parentId, subCollectionId = null) {
    const parentRef = db.collection('clientAccounts').doc(parentId);
    const changeRequestRef = parentRef.collection('changeRequest');

    if (!subCollectionId) {
        // Create a new sub-collection ID if not provided
        subCollectionId = changeRequestRef.doc().id;
    }

    if (changeRequestObj) {
        // Set the change request data
        const requestData = {
            ...changeRequestObj,
            parentRef: parentRef,
            collectionName: 'clientAccounts',
            parentId: parentId,
            id: subCollectionId,
        };

        const requestRef = changeRequestRef.doc(subCollectionId);
        if (await requestRef.get().exists) {
            await requestRef.update(requestData);
        } else {
            await requestRef.set(requestData);
        }
    }
}

async function getAccountUpdateRequest(id) {
    try {
        const parentRef = db.collection('clientAccounts').doc(id);
        const changeRequestRef = parentRef.collection('changeRequest');
        const snapshot = await changeRequestRef.get();

        const changeRequests = [];
        snapshot.forEach((doc) => {
            changeRequests.push(doc.data());
        });

        return changeRequests;
    } catch (error) {
        console.error('Error getting change requests:', error);
        return [];
    }
}

export default {
    getAccountListener,
    saveAccountUpdateRequest,
    getAccountUpdateRequest
}

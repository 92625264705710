import _ from 'lodash';
import moment from 'moment';

function diffClientAccounts(oldAccount, newAccount) {
    const difference = [];
    let counter = 0;

    function addDifference(fieldUpdate, from, to, critical = false) {
        difference.push({ index: ++counter, fieldUpdate, from, to, critical });
    }

    function compareAssetTypes(newAsset, oldAsset) {
        if (newAsset.rentFrequency !== oldAsset.rentFrequency) {
            addDifference(
                `${newAsset.assetType} / Rent Frequency`,
                oldAsset.rentFrequency,
                newAsset.rentFrequency,
                true
            );
        }

        if (newAsset.rentRate !== oldAsset.rentRate) {
            addDifference(
                `${newAsset.assetType} / Rent Rate`,
                `${oldAsset.rentRate} PHP`,
                `${newAsset.rentRate} PHP`,
                true
            );
        }

        if (newAsset.quantity !== oldAsset.quantity) {
            addDifference(
                `${newAsset.assetType} / Contracted Quantity`,
                oldAsset.quantity,
                newAsset.quantity,
                true
            );
        }

        // BILLABLE RATES

        let newBillableRates = newAsset.billableRates;
        let oldBillableRates = oldAsset.billableRates;

        if (!_.isEmpty(newBillableRates) && _.isEmpty(oldBillableRates)) {
            _.forEach(newBillableRates, value => {
                addDifference('Billable Rates', '', `Added <strong style="color: #3F8120">${value.condition}</strong> to ${newAsset.assetType}`, true);
            })
        } else if (_.isEmpty(newBillableRates) && !_.isEmpty(oldBillableRates)) {
            _.forEach(oldBillableRates, value => {
                addDifference('Billable Rates', '', `Removed <strong style="color: #E93F19">${value.condition}</strong> to ${newAsset.assetType}`, true);
            })
        } else {
            _.forEach(newBillableRates, (value, key) => {
                const oldBillable = oldBillableRates[key];
                if (oldBillable) {
                    compareBillables(newAsset, value, oldBillable);
                } else {
                    addDifference('Billable Rates', '', `Added <strong style="color: #3F8120">${value.condition}</strong> to ${newAsset.assetType}`, true);
                }
            });

            _.forEach(oldBillableRates, (value, key) => {
                const newBillable = newBillableRates[key];
                if (!newBillable) {
                    addDifference('Billable Rates', '', `Removed <strong style="color: #E93F19">${value.condition}</strong> to ${newAsset.assetType}`, true);
                }
            });
        }
    }

    function compareBillables(newAsset, newBillable, oldBillable) {
        if (newBillable.condition !== oldBillable.condition) {
            addDifference(
                `${newAsset.assetType} / Billable Rate / Condition`,
                oldBillable.condition,
                newBillable.condition,
                true
            );
        }

        if (newBillable.description !== oldBillable.description) {
            addDifference(
                `${newAsset.assetType} / Billable Rate / Description`,
                oldBillable.description,
                newBillable.description,
                true
            );
        }

        if (newBillable.vatExclusiveRate !== oldBillable.vatExclusiveRate) {
            addDifference(
                `${newAsset.assetType} / VAT Exclusive Rate`,
                oldBillable.vatExclusiveRate,
                newBillable.vatExclusiveRate,
                true
            );
        }
    }

    if (!_.isEmpty(oldAccount)) {

        if (newAccount.assetOwnerId !== oldAccount.assetOwnerId) {
            addDifference('Asset Provider', oldAccount.assetOwner, newAccount.assetOwner);
        }

        if (newAccount.clientId !== oldAccount.clientId) {
            addDifference('Client', oldAccount.client, newAccount.client);
        }

        if (newAccount.principalId !== oldAccount.principalId) {
            addDifference('Principal', oldAccount.principal, newAccount.principal);
        }

        if (newAccount.accountNo !== oldAccount.accountNo) {
            addDifference('Account No', oldAccount.accountNo, newAccount.accountNo);
        }

        if (newAccount.accountManagerId !== oldAccount.accountManagerId) {
            addDifference('Account Manager', oldAccount.accountManager, newAccount.accountManager);
        }

        if (newAccount.clientManagerId !== oldAccount.clientManagerId) {
            addDifference('Client Manager', oldAccount.clientManager, newAccount.clientManager);
        }

        if (newAccount.remarks !== oldAccount.remarks) {
            addDifference('Remarks', oldAccount.remarks, newAccount.remarks);
        }

        if (newAccount.status !== oldAccount.status) {
            addDifference('Status', oldAccount.status, newAccount.status);
        }

        if (newAccount.contractPeriod !== oldAccount.contractPeriod) {
            addDifference('Contract Period', oldAccount.contractPeriod, newAccount.contractPeriod, true);
        }

        if (newAccount.startDate !== oldAccount.startDate) {
            addDifference(
                'Contract Start Date',
                getFormattedDate(oldAccount.startDate),
                getFormattedDate(newAccount.startDate),
                true
            );
        }

        if (newAccount.billingFrequency !== oldAccount.billingFrequency) {
            addDifference('Billing Frequency', oldAccount.billingFrequency, newAccount.billingFrequency, true);
        }

        if (newAccount.billingSchedule !== oldAccount.billingSchedule) {
            addDifference('Billing Schedule', oldAccount.billingSchedule, newAccount.billingSchedule, true);
        }

        // ASSET TYPES
        let newAssets = newAccount.assetTypes;
        let oldAssets = oldAccount.assetTypes;

        if (!_.isEmpty(newAssets) && _.isEmpty(oldAssets)) {
            _.forEach(newAssets, value => {
                addDifference('Asset Types', '', `Added <strong style="color: #3F8120">${value.assetType}</strong>`, true);
            })
        } else if (_.isEmpty(newAssets) && !_.isEmpty(oldAssets)) {
            _.forEach(oldAssets, value => {
                addDifference('Asset Types', '', `Removed <strong style="color: #E93F19">${value.assetType}</strong>`, true);
            })
        } else {
            _.forEach(newAssets, (value, key) => {
                let oldAsset = oldAssets[key];
                if (oldAsset) {
                    compareAssetTypes(value, oldAsset);
                } else {
                    addDifference('Asset Types', '', `Added <strong style="color: #3F8120">${value.assetType}</strong>`, true);
                }
            });

            _.forEach(oldAssets, (value, key) => {
                let newAsset = newAssets[key];
                if (!newAsset) {
                    addDifference('Asset Types', '', `Removed <strong style="color: #E93F19">${value.assetType}</strong>`, true);
                }
            });
        }
    } else {
        addDifference('Client account', 'N/A', `Created ${newAccount.accountNo} client account`);
    }

    return difference;
}


function getFormattedDate(date) {
    return moment(date).format('MMMM D, YYYY');
}

export const DifferenceUtil = {
    diffClientAccounts,
};

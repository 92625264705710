<template>
	<b-modal id="print-client-account" :title="title" size="xl" ok-title="Download" ref="modal"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container v-show="selClientAccount !== null">
			<div v-for="(copy, key) in copies" :key="key">
				<div class="page-container" :id="getKey(copy, page - 1)" v-for="page in totalPage" :key="page"
					v-show="page - 1 === currPage">
					<!-- Header -->
					<ClientAccountSummaryHeader :selClientAccount="selClientAccount" />

					<b-row class="px-1 mb-2">
						<b-col sm="12" class="my-2 px-0">
							<ClientAccountSummaryRental :rentals="rentalsBy1[page - 1]" />
						</b-col>
						<b-col sm="12" class="mb-2 px-0">
							<ClientAccountSummaryRepair :rentals="rentalsBy1[page - 1]" :repairs="repairs" />
						</b-col>
					</b-row>

					<!-- Footer -->
					<ClientAccountSummaryFooter :currPage="page - 1" :totalPage="totalPage" :copyOwner="copy" />
				</div>
			</div>
		</b-container>

		<template #modal-footer="{ cancel }">
			<b-row class="w-100">
				<b-col class="float-left">
					<b-button variant="success" @click="prevPage" class="footer-button">
						Back
					</b-button>
					<b-button variant="success" @click="nextPage" class="footer-button">
						Next
					</b-button>
					<span class="pagination-status">
						Page <strong>{{ currPage + 1 }}</strong> of <strong>{{ totalPage }}</strong>
					</span>
				</b-col>

				<b-col class="text-right">
					<b-button variant="secondary" @click="cancel()" class="footer-button">
						Close
					</b-button>
					<b-button variant="primary" @click="confirmPrintAccountSummary" class="footer-button">
						Print
					</b-button>
				</b-col>
			</b-row>
		</template>

		<ConfirmPrintClientAccount :selClientAccount="selClientAccount"
			@onConfirmPrintClientAccount="printClientAccount" />
	</b-modal>
</template>

<script>
// Components
import ClientAccountSummaryHeader from './summary/ClientAccountSummaryHeader.vue';
import ClientAccountSummaryRental from './summary/ClientAccountSummaryRental.vue';
import ClientAccountSummaryRepair from './summary/ClientAccountSummaryRepair.vue';
import ConfirmPrintClientAccount from './summary/ConfirmPrintClientAccount.vue';
import ClientAccountSummaryFooter from './summary/ClientAccountSummaryFooter.vue';

// Util
import { ClientAccountUtil } from '@/utils/clientAccountUtil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';


export default {
	name: 'print-account-summary',
	components: {
		ClientAccountSummaryHeader,
		ClientAccountSummaryRental,
		ClientAccountSummaryRepair,
		ConfirmPrintClientAccount,
		ClientAccountSummaryFooter,
		Loading,
	},
	data() {
		return {
			selClientAccount: {},

			copies: [''],
			currPage: 0,

			isLoading: false,
			isRendering: false
		};
	},
	computed: {
		title() {
			return 'Print Client Account ' + this.selClientAccount.accountNo;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		accountNo() {
			let client = this.selClientAccount ? this.selClientAccount : {};
			return client.accountNo ? client.accountNo : "-";
		},
		totalPage() {
			return _.size(this.selClientAccount.assetTypes);
		},
		rentals() {
			let assetTypesObj = this.selClientAccount.assetTypes;
			if (!_.isEmpty(assetTypesObj)) {

				let rentalsArr = [];

				let index = 0;
				_.forEach(assetTypesObj, value => {
					rentalsArr.push({
						id: index,
						asset: value.assetType,
						rentFrequency: value.rentFrequency,
						rentRate: value.rentRate,
						quantity: value.quantity
					});
					index++;
				});

				return rentalsArr;
			}
		},
		repairs() {
			let repairsArr = [];

			let assetTypes = this.selClientAccount && this.selClientAccount.assetTypes ? this.selClientAccount.assetTypes : {};
			_.forEach(assetTypes, value => {
				const billablesObj = value.billableRates;
				if (!_.isEmpty(billablesObj)) {
					_.forEach(billablesObj, billable => {
						repairsArr.push({
							asset: value.assetType,
							condition: billable.condition,
							description: billable.description,
							vatExRate: billable.vatExclusiveRate
						});
					});
				}
			});

			return repairsArr;
		},
		rentalsBy1() {
			return _.chunk(this.rentals, 1);
		},
	},
	mounted() {
		EventBus.$on('onPrintClientAccount', (selClientAccount) => {
			this.onReset();

			if (!_.isEmpty(selClientAccount)) {
				this.selClientAccount = ClientAccountUtil.cleanupFields(selClientAccount);
			}
		});
	},
	methods: {
		confirmPrintAccountSummary(event) {
			event.preventDefault();
			this.$bvModal.show('confirm-print-client-account');
		},
		async printClientAccount(param) {
			try {
				// show loading indicator
				this.isLoading = true;

				let type = param.type;
				if (type === 'Single Copy') {
					this.copies = [''];
				} else {
					this.copies = [...param.copies];
				}

				setTimeout(async () => {
					await this.downloadMultipleCopies(this.copies, this.totalPage);
					this.$refs.modal.hide();
					this.onReset();
				}, 4000);
			} catch (error) {
				console.error('Error occurred:', error);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		// Multiple Copies
		async downloadMultipleCopies(copies, totalPage) {
			let imageElements = [];
			for (let i = 0; i < copies.length; i++) {
				let copyOwner = copies[i];
				for (let j = 0; j < totalPage; j++) {
					let id = this.getKey(copyOwner, j);
					const containerElement = document.getElementById(id);
					imageElements.push({
						element: containerElement,
						copyOwner: copyOwner,
						key: j
					});
				}
			}

			const zip = new JSZip();
			const promises = [];

			_.forEach(imageElements, imageElement => {
				let element = imageElement.element;
				let copyOwner = imageElement.copyOwner;
				let key = imageElement.key;

				let filename = this.getFileName(copyOwner, key);
				promises.push(this.captureAndAddToZip(element, zip, filename));
			});
			await Promise.all(promises);

			const content = await zip.generateAsync({ type: 'blob' });

			let zipFilename = this.accountNo + '.zip';
			saveAs(content, zipFilename);
		},
		async captureAndAddToZip(element, zip, filename) {

			// Make the element temporarily visible
			const originalDisplay = element.style.display;
			element.style.display = 'block';

			// Add a delay before capturing the image
			await new Promise(resolve => setTimeout(resolve, config.timeout));
			const canvas = await html2canvas(element);

			// Restore the original display property
			element.style.display = originalDisplay;

			return new Promise((resolve) => {
				canvas.toBlob((blob) => {
					zip.file(filename, blob);
					resolve();
				});
			});
		},

		getKey(copyOwner, key) {
			return 'client-account-summary-' + copyOwner + '-' + key;
		},
		getFileName(copyOwner, key) {
			let currPage = key + 1;
			if (this.totalPage > 1) {
				return this.accountNo + '_' + copyOwner + '_Copy' + currPage + '_of_' + this.totalPage + '.png';
			} else {
				return this.accountNo + '_' + copyOwner + '_Copy.png';
			}
		},

		onReset() {
			this.selClientAccount = {};

			this.copies = [''];
			this.currPage = 0

			// Check for loader
			this.isLoading = false;
			this.isRendering = false;
		},

		prevPage() {
			if (this.currPage > 0) {
				this.currPage = this.currPage - 1;
			}
		},
		nextPage() {
			if (this.currPage < this.totalPage - 1) {
				this.currPage = this.currPage + 1;
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onPrintClientAccount');
	},
};
</script>

<style scoped>
.page-container {
	padding-top: 1em;
	padding-left: 1.75em;
	padding-right: 1.75em;

	position: relative;
	/* Maintains a 13:9 aspect ratio */
    aspect-ratio: 13 / 9;  
    max-width: 100wh;
	overflow: visible;
}

.footer-button {
	margin-left: 5px;
}

.pagination {
	padding: 0.7em;
	text-transform: uppercase;
	vertical-align: middle;
	position: absolute;
	left: 1000px;
	bottom: 20px;
}

.pagination-status {
	padding: 0.7em;
	text-transform: uppercase;
	vertical-align: middle;
}
</style>
